import LogRocket from 'logrocket';
import { AppProps } from 'next/app';
import { continueExistingLogRocketSession } from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import 'intersection-observer';
import { useEffect } from 'react';
import { isPostAuthNav3MobileOrDesktop } from 'hooks/useIsPostAuthNav3Active';
import { Global, css } from '@emotion/react';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY_V2, { SCREEN_MASSIVE } from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import 'styles/global/global.less';

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
  window.analytics.page();
}

export default function App({
  Component,
  pageProps,
  err,
}: AppProps & {
  err?: unknown;
}): JSX.Element {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LogRocket = LogRocket;
      continueExistingLogRocketSession();
    }
  }, []);

  const isPostAuthNav3 = isPostAuthNav3MobileOrDesktop(pageProps.userCtx || pageProps.userContext);

  const globalStyles = css`
    ${MEDIA_QUERY_V2.DESKTOP_MASSIVE} {
      .root-page-background {
        background-color: ${COLORS3.BLACK_005};
      }

      .root-page-max-width {
        max-width: ${SCREEN_MASSIVE}px;
        min-height: 100vh;
        background-color: ${COLORS3.WHITE_100};
        border-right: 1px solid ${COLORS3.BLACK_030};
        #nav-ssr-root,
        #root-nav {
          max-width: ${SCREEN_MASSIVE}px;
          border-right: 1px solid ${COLORS3.BLACK_030};
        }
      }
    }
  `;

  return (
    <>
      {isPostAuthNav3 ? <Global styles={globalStyles} /> : null}
      <div className="root-page-background">
        <div className="root-page-max-width">
          <Component {...pageProps} err={err} />
        </div>
      </div>
    </>
  );
}
